// Importing the namespace will set the emitter to venus inside window object
// we don't need to use the namespace anywhere in the code

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ns from './ns';
import eventNameConst from './constants/idsAndNames';
import { getEnv } from './helpers/apiEnv';

const { venusUrl } = getEnv();

/* eslint-disable no-console */
(function venera(w: any, d) {
  if (!w || !d) {
    console.error('Running refrens script failed');
    return;
  }

  const scriptTag = d.createElement('script');

  /* eslint-disable no-unused-expressions */

  ns?.emit(`${eventNameConst.veneraLoadPrefix}`, {
    status: 'success',
  });

  fetch(`${venusUrl}/manifest.json`, { cache: 'no-store' })
    .then((response) => response.json())
    .then(({ version } = {}) => {
      if (version) {
        scriptTag.async = true;
        scriptTag.src = `${venusUrl}/venus.${version}.js`;
        scriptTag.onerror = (err) => {
          console.error('Loading venus script failed -', err);
          ns?.emit(`${eventNameConst.venusScriptPrefix}`, {
            version,
            error: err,
            status: 'failed',
          });
        };
        scriptTag.onload = () => {
          ns?.emit(`${eventNameConst.venusScriptPrefix}`, { version, status: 'success' });
        };

        d.body.appendChild(scriptTag);
      } else {
        console.error('Loading venus script failed');
      }
    })
    .catch((e) => console.error(e));

  /* eslint-enable no-unused-expressions */
})(window, document);
/* eslint-enable no-console */
