// Constant used for selecting/creating/updating DOM elements
export default {
  hideFormClass: 'RFEM-modal-overlay--hidden',
  popupFormIdPrefix: 'refrens-popup-form-',
  inlineFormIdPrefix: 'refrens-inline-form-',
  openPopupEventName: 'form:open-popup',
  showModalEventPrefix: 'show-ref-modal-',
  formSubmitPrefix: 'form:submit',
  venusScriptPrefix: 'venus-script:load',
  veneraLoadPrefix: 'venera:load',
  venusLoadPrefix: 'venus:load',
};
