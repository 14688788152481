import { Env, Venus } from '../types';

import ns from '../ns';

// TODO: Need to configure env files
const defaultEnv: Env = {
  apiEndpoint: 'https://serana.qa03.bizsuggest.com',
  reCaptchaSiteKey: '6Lcu3ewUAAAAAOGkUVSeBGjNMACJjG-HIwSB78f4',
  brandingUrl:'https://www.refrens.com/lead-management-software?utm_source=LMS&utm_medium=forms&utm=campaign=refrensbranding',
  sentryDNS: 'https://8112de78564b44b1ba740595908e56be@0284136.ingest.sentry.io/4504542130929664',
  greCaptchaLink: 'https://www.google.com/recaptcha/api.js?render=explicit',
  captchaId: 'RFEM-g-recaptcha',
  schemaId: 'contact-form',
  venusUrl: 'https://venus.qa01.bizsuggest.com',
};

export function getEnv(): Env {
  const { env = defaultEnv } = ns as Venus;

  return env;
}

export function setEnv(params: Partial<Env>): void {
  const { env = defaultEnv } = ns as Venus;
  ns.env = {
    ...(env as Env),
    ...params,
  };
}
