// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import emitter from 'tiny-emitter/instance';
import { RefrensRoot } from './types';

const { $refrens = { venus: {} } as RefrensRoot } = window;
const { venus = {} as RefrensRoot['venus'] } = $refrens;

const ns = Object.assign(emitter, venus);

$refrens.venus = ns;
// set emitter to venus inside window object
// so that it can be used by the user for custom events
// User can listen to the events released by venus and do whatever they want on those events
window.$refrens = $refrens;

export default ns;
